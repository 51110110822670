html{
  overflow-x: hidden!important;
  --primaryColor:#212121;
}
body,#root{
  font-family: "Jost",sans-serif;
  overflow-x: hidden;
}
.navbar{
 height: 120px;
 font-size: 20px;
 background-color: white!important;
}

.nav-item{
  padding: 0 20px;
}
.nav-link{
  color:#212121;
  }
.nav-link:hover,#footer>div>ul>li>a:hover{
  color: #a4bd01;
}
@media screen and (max-width: 990px){
  .navbar-collapse{
    width: 100vw;
      position: absolute;
      top: 100px;
      left: 0;
      
  }
  .navbar-nav{
    padding: 10px 0;
  }
  #navbar-toggler{
    background-color: #212121;
    color: white;
    padding: 10px 20px;
  }

}

@media screen and (max-width: 450px){
  .navbar-brand img{
    width: 150px!important;
  }
  .navbar-brand{
    padding: 0!important;
  }
  .navbar-collapse{
      top: 50px;
  }
}


@media screen and (max-width:1000px) {
  #navbarToggle{
    position: absolute;
    right: 30px;
  }
  .navbar-brand{
    padding-left: 50px;
  }
  h1{
    width: 26rem;
  }
  #homeVid{
    width: 100rem !important;
    right: -250px;
  }
  #footer  .col-lg-4,#footer .col-lg-3{
    align-items: center;
  }
  form{
    padding: 0 30px;
  }
}
.card{
  margin: 30px 60px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  transition: 0.3s;
}
#footer .col-md-4 {
  padding-top: 20px!important;
}

.card-img-top{
  border-radius: 20px 20px 0 0;
}
.card:hover{
  scale: 1.1;
}
#footer{
  background-color: #212121!important;
  
}
#footer>span{
  
  display: flex;
  justify-content: center;
  flex-direction: column;

}
#footer,#footer>div>ul>li>a{
  color: white;
  font-size: 18px;
  padding: 5px 0;
}
.footer-logo{
  width: 300px;
}
.footer-heading{
  font-size: 30px;
  font-weight: 300;
}
.btn-primary{
  background-color: #212121!important;
  padding: 10px 25px;
  border: none;
  margin: 0;
  transition: background-color 0.1s linear;
}
.btn-primary:hover{
  color: black!important;
  background-color: #a4bd01!important;
}
#homeVid{
  height: 100%;
    width: 100%;
    object-fit: fill
}
.nav-item{
  padding: 8px 30px;
}

.card{
  border-radius: 5px;
  padding: 0;
  border:none;
}
.card-img-top{
  padding: 5px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height:305px;
}

.contact-us-container {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 80px;
}

.logo {
  font-size: 36px;
  color: #2a9d8f;
  margin-bottom: 20px;
}

.contact-info {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.business-hours {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.reach-out {
  border-radius: 10px;
  padding: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
